import {axiosData} from "@/store/api/axios";

const state = () => ({
  cupones: null || JSON.parse(localStorage.getItem('cupones')),
  cupon: null || JSON.parse(localStorage.getItem('cupon'))
});

const getters = {
  getZonas(state) {
    return state.cupones;
  },
  getZona(state) {
    return state.cupon;
  },
};

const actions = {
  setZona({ commit }, payload) {
    commit('SET_CUPON', payload);
    payload
        ? localStorage.setItem('cupon', JSON.stringify(payload))
        : localStorage.removeItem('cupon');
  },
  setZonas({ commit }, payload) {
    commit('SET_CUPONES', payload);
    payload
        ? localStorage.setItem('cupones', JSON.stringify(payload))
        : localStorage.removeItem('cupones');
  },
  async busquedaCupon(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Traer Cupon');

    try {
      const data = await axiosData(
          true,
          'acto/cupon/busqueda',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);

        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getCuponesByActo(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Traer Cupones');

    try {
      const data = await axiosData(
          true,
          'acto/cupon/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data !== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);

        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async saveCupon(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'acto/cupon/save',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }}
};

const mutations = {
  SET_CUPONES(state, payload) {
    state.cupones = payload;
    localStorage.setItem('cupones', JSON.stringify(payload));
  },
  SET_CUPON(state, payload) {
    state.cupon = payload;
    localStorage.setItem('cupon', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
