import {axiosData} from "@/store/api/axios";

const state = () => ({
  cliente: null || JSON.parse(localStorage.getItem('cliente')),
  clientes: null || JSON.parse(localStorage.getItem('clientes')),
  iseventos: null || JSON.parse(localStorage.getItem('iseventos')),
});

const getters = {
  getClientes(state) {
    return state.clientes;
  },
  getCliente(state) {
    return state.cliente;
  },
  getIsEventos(state) {
    return state.iseventos;
  },

};

const actions = {
  setCliente({ commit }, payload) {
    commit('SET_CLIENTE', payload);
    payload
        ? localStorage.setItem('cliente', JSON.stringify(payload))
        : localStorage.removeItem('cliente');
  },
  setClientes({ commit }, payload) {
    commit('SET_CLIENTES', payload);
    payload
        ? localStorage.setItem('clientes', JSON.stringify(payload))
        : localStorage.removeItem('clientes');
  },
  async getClientePasarela(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Traer Pasarela');

    try {
      const data = await axiosData(
          true,
          'clientes/pasarela',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  setIsEventos({ commit }, payload) {
    commit('SET_ISEVENTOS', payload);
    payload
        ? localStorage.setItem('iseventos', JSON.stringify(payload))
        : localStorage.removeItem('iseventos');
  },

};

const mutations = {
  SET_CLIENTE(state, payload) {
    state.cliente = payload;
    localStorage.setItem('cliente', JSON.stringify(payload));
  },
  SET_CLIENTES(state, payload) {
    state.clientes = payload;
    localStorage.setItem('clientes', JSON.stringify(payload));
  },
  SET_ISEVENTOS(state, payload) {
    state.iseventos = payload;
    localStorage.setItem('iseventos', JSON.stringify(payload));
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
