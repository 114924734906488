import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import router from "./routes/index";
import i18n from "@/i18n";
import store from "./store";
import VueExcelXlsx from "vue-excel-xlsx";
import VueMeta from "vue-meta";
import VueQrcodeReader from "vue-qrcode-reader";
import VueClipboard  from "vue-clipboard2";
import browserDetect from "vue-browser-detect-plugin";
import VueSocialSharing from 'vue-social-sharing'
import titleMixin from "@/mixins/titleMixin";
import VueNumber from 'vue-number-animation'
import x5GMaps from 'x5-gmaps';
import device from "vue-device-detector"
import IdleVue from 'idle-vue';
import VueMarqueeSlider from 'vue-marquee-slider';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './assets/style.css';




window.axios = axios;
axios.defaults.baseURL = process.env.VUE_APP_API;
Vue.config.productionTip = false;
VueClipboard.config.autoSetContainer = true
Vue.mixin(titleMixin)
Vue.use(VueExcelXlsx);
Vue.use(device)

Vue.use(VueMeta);
Vue.use(VueQrcodeReader);
Vue.use(VueClipboard);
Vue.use(browserDetect);
Vue.use(VueSocialSharing);
Vue.use(VueNumber);
Vue.use(require('vue-script2'))
Vue.use(x5GMaps, { key: 'AIzaSyCXm1SR1hOSNMDrCOeMQr7XQSDSS-YKyBg', libraries: ['places'] });

Vue.use(VueMarqueeSlider)




const eventsHub = new Vue();

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 10000,
    // startAtIdle: false,
    events: [
        'mousemove',
        'keydown',
        'mousedown',
        'touchstart',
        'scroll',
        'input',
    ],
});


new Vue({
    i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
