import {axiosData} from "@/store/api/axios";

const state = () => ({
  personas: null || JSON.parse(localStorage.getItem('personas')),
  persona: null || JSON.parse(localStorage.getItem('persona'))
});

const getters = {
  getPersonas(state) {
    return state.personas;
  },
  getPersona(state) {
    return state.persona;
  },
};

const actions = {
  setPersona({ commit }, payload) {
    commit('SET_PERSONA', payload);
    payload
        ? localStorage.setItem('persona', JSON.stringify(payload))
        : localStorage.removeItem('persona');
  },
  setPersonas({ commit }, payload) {
    commit('SET_PERSONAS', payload);
    payload
        ? localStorage.setItem('personas', JSON.stringify(payload))
        : localStorage.removeItem('personas');
  },
  async setFotoPerfil(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const bodyFormData = new FormData();
      bodyFormData.append("file",payload.imagen);
      const data = await axiosData(
          true,
          'personas/foto/'+payload.id,
          bodyFormData,
          'post',
          false,
          false
      );
      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async verPersona(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'personas/versocio',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async grabarPersonaNegocio(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'personas/negocio/grabar',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async grabarPersona(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
       await axiosData(
          true,
          'personas/save',
          payload,
          'post',
          false,
          false
      );
      this.dispatch('ui/handleLoaderBtn', false);
      this.dispatch('personas/setPersona', payload);

    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getPersonasCumples(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'personas/cumples/'+id,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getPersonasNegocio(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'personas/negocio/'+id,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);

        return data.filter(item => item.persona.id != 1);
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getAsistencia(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'personas/asistencia/'+id,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getRanking(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'personas/asistencia/ranking/'+id,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getPersonasByPerson(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'personas/'+id,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('personas/setPersonas', data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }}
};

const mutations = {
  SET_PERSONAS(state, payload) {
    state.personas = payload;
    localStorage.setItem('personas', JSON.stringify(payload));
  },
  SET_PERSONA(state, payload) {
    state.persona = payload;
    localStorage.setItem('persona', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
