import {axiosData} from "@/store/api/axios";

const state = () => ({
  eventos: null || JSON.parse(localStorage.getItem('eventos')),
  evento: null || JSON.parse(localStorage.getItem('evento')),
  entrada: null || JSON.parse(localStorage.getItem('entrada'))
});

const getters = {
  getEventos(state) {
    return state.eventos;
  },
  getEvento(state) {
    return state.evento;
  },
  getEntrada(state) {
    return state.entrada;
  },
};

const actions = {
  setEvento({ commit }, payload) {
    commit('SET_EVENTO', payload);
    payload
        ? localStorage.setItem('evento', JSON.stringify(payload))
        : localStorage.removeItem('evento');
  },
  setEntrada({ commit }, payload) {
    commit('SET_ENTRADA', payload);
    payload
        ? localStorage.setItem('entrada', JSON.stringify(payload))
        : localStorage.removeItem('entrada');
  },
  setEventos({ commit }, payload) {
    commit('SET_EVENTOS', payload);
    payload
        ? localStorage.setItem('eventos', JSON.stringify(payload))
        : localStorage.removeItem('eventos');
  },
  async nuevaEntrada(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Grabando Entradas');

    try {
      const data = await axiosData(
          true,
          'eventoentradas/grabar',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('eventos/setEvento',data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async nuevoEvento(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Grabando Evento');

    try {
      const data = await axiosData(
          true,
          'eventos/nuevo',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('eventos/setEvento',data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabarEvento(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Grabando Evento');

    try {
      const data = await axiosData(
          true,
          'eventos/grabaevento',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabarContactoEvento(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'eventos/contactos/grabar',
          payload,
          'post',
          false,
          false
      );

      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async borrarEvento(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'eventos/'+tag,
          'delete',
          false,
          false
      );


      this.dispatch('ui/handleLoaderBtn', false);
      return data;

    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getEventoEntradasContacto(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Obteniendo Entradas');

    try {
      const data = await axiosData(
          true,
          'contactos/entradas/'+tag,
          'get',
          false,
          false
      );


      this.dispatch('ui/handleLoaderBtn', false);
      return data;

    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getEventoEntradas(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Obteniendo Entradas');

    try {
      const data = await axiosData(
          true,
          'eventoentradas/evento/'+tag,
          'get',
          false,
          false
      );


      this.dispatch('ui/handleLoaderBtn', false);
      return data;

    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },

  async getEventoEntradasPago(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Obteniendo Entradas');

    try {
      const data = await axiosData(
          true,
          'eventoentradas/evento/'+tag,
          'get',
          false,
          false
      );


      this.dispatch('ui/handleLoaderBtn', false);
      return data.filter(item=> item.precio > 0);

    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getEntradaById(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'eventoentradas/entrada/'+tag,
          'get',
          false,
          false
      );


      this.dispatch('ui/handleLoaderBtn', false);
      return data;

    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getEventosVerifica(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'eventos/verifica/'+tag,
          'get',
          false,
          false
      );


        this.dispatch('ui/handleLoaderBtn', false);
        return data;

    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getEventosByCliente(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Traer Eventos');

    try {
      const data = await axiosData(
          true,
          'eventos/cliente/'+tag,
          'get',
          false,
          false
      );

      if(data !== 404 && data !== null && data!== 400){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('eventos/setEventos',data);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
};

const mutations = {
  SET_EVENTOS(state, payload) {
    state.eventos = payload;
    localStorage.setItem('eventos', JSON.stringify(payload));
  },
  SET_EVENTO(state, payload) {
    state.evento = payload;
    localStorage.setItem('evento', JSON.stringify(payload));
  },
  SET_ENTRADA(state, payload) {
    state.entrada = payload;
    localStorage.setItem('entrada', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
